import React, { useEffect, useState } from "react";
import {
  DropdownButton,
  Row,
  Dropdown,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import HeroImage from "../components/HeroImage/HeroImage";
import Layout from "../components/Layout";
import "../scss/inspirations.scss";
import { blogPosts } from "../data/blogposts";
import { Link } from "gatsby";
import { PlayCircleFill } from "react-bootstrap-icons";
import _ from "lodash";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const Inspirations = () => {
  const imageBack = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativePath: { in: "inspirations/solution-header-2400.jpg" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                formats: WEBP
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      }
    }
  `);
  const heroInspirations = getImage(imageBack.allFile.edges[0].node);
  const heroInspirationsBG = convertToBgImage(heroInspirations);
  const useWindowWidth = () => {
    const isBrowser = typeof window !== "undefined";
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

    useEffect(() => {
      if (!isBrowser) return false;

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    return width;
  };

  const dataArray = [...blogPosts];
  const [cards, setCards] = useState(dataArray);
  const [dropdownTitle, setDropdownTitle] = useState("Filter by Category");
  let filterSelected = "all";

  const filterValueChanged = (selected) => {
    filterSelected = selected;
    filterCards(selected);
  };

  const filterCards = (fSelected) => {
    let filterData = [];
    if (fSelected !== "all") {
      filterData = _.filter(dataArray, (obj) => {
        return _.includes(obj.category, filterSelected);
      });
    } else {
      filterData = dataArray;
    }

    setCards(filterData);
  };

  const isActive = (selected) => {
    if (dropdownTitle === selected) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Layout title="Inspirations" description="">
      <main>
        <BackgroundImage
          Tags="sections"
          preserveStackingContext
          {...heroInspirationsBG}
          className="heroInspirations"
          alt="Inspirations Banner"
        >
          <Row className="row">
            <HeroImage
              title={"INSPIRATIONS"}
              subtitle={`Let us inspire your everyday life! Sara Lee® Premium Meats is your go-to resource for delicious meal solutions your whole family will love, how-to videos, simple lifestyle tips, entertaining ideas, meal hacks & more!`}
              subtitleClassname={"white"}
              isMainTitle={true}
            ></HeroImage>
          </Row>
        </BackgroundImage>
        <Row as={"section"}>
          <div className="inner-center">
            <h2 className="inner-center--half title-category">CATEGORIES</h2>
            <ToggleButtonGroup
              md={6}
              className="inner-center--filter"
              name="categoryButtonGroup"
              type="radio"
            >
              <ToggleButton
                className="inner-center--filter--filterButton"
                onClick={() => filterValueChanged("meal")}
                value="meal"
                id="tbg-p-radio-1"
              >
                MEALS
              </ToggleButton>
              <ToggleButton
                className="inner-center--filter--filterButton"
                onClick={() => filterValueChanged("big-game")}
                value="big-game"
                id="tbg-p-radio-2"
              >
                BIG GAME
              </ToggleButton>
              <ToggleButton
                className="inner-center--filter--filterButton"
                onClick={() => filterValueChanged("holiday")}
                value="holiday"
                id="tbg-p-radio-3"
              >
                HOLIDAY
              </ToggleButton>
              <ToggleButton
                className="inner-center--filter--filterButton"
                onClick={() => filterValueChanged("on-the-go")}
                value="on-the-go"
                id="tbg-p-radio-4"
              >
                ON THE GO
              </ToggleButton>
              <ToggleButton
                className="inner-center--filter--filterButton"
                onClick={() => filterValueChanged("back-to-school")}
                value="back-to-school"
                id="tbg-p-radio-5"
              >
                BACK TO SCHOOL
              </ToggleButton>
              <ToggleButton
                className="inner-center--filter--filterButton"
                onClick={() => filterValueChanged("all")}
                value="all"
                id="tbg-p-radio-6"
              >
                ALL
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="inner-center--filter-mobile">
              <h4>Category</h4>

              {/*Dropdown*/}
              <DropdownButton
                id="dropdown-filter"
                title={dropdownTitle}
                variant="outline-dark"
                size="lg"
                className="d-block"
              >
                <Dropdown.Item
                  active={isActive("ALL") || isActive("Filter by Category")}
                  onClick={() => {
                    filterValueChanged("all");
                    setDropdownTitle("ALL");
                  }}
                >
                  ALL
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive("MEALS")}
                  onClick={() => {
                    filterValueChanged("meal");
                    setDropdownTitle("MEALS");
                  }}
                >
                  MEALS
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive("BIG GAME")}
                  onClick={() => {
                    filterValueChanged("big-game");
                    setDropdownTitle("BIG GAME");
                  }}
                >
                  BIG GAME
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive("HOLIDAY")}
                  onClick={() => {
                    filterValueChanged("holiday");
                    setDropdownTitle("HOLIDAY");
                  }}
                >
                  HOLIDAY
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive("ON THE GO")}
                  onClick={() => {
                    filterValueChanged("on-the-go");
                    setDropdownTitle("ON THE GO");
                  }}
                >
                  ON THE GO
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive("BACK TO SCHOOL")}
                  onClick={() => {
                    filterValueChanged("back-to-school");
                    setDropdownTitle("BACK TO SCHOOL");
                  }}
                >
                  BACK TO SCHOOL
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </Row>
        <section className="inner-center">
          <Row
            md={useWindowWidth() >= "990" ? 4 : 3}
            sm={useWindowWidth() >= "500" ? 2 : 1}
          >
            {cards?.map((item, i) => (
              <div className={item.video ? "video-post" : "blog-post"}>
                {item.video ? (
                  <Link
                    to={"/inspirations/" + item.blogLink}
                    className="video-post--video-link"
                  >
                    <div className="video-post--video-link--contain">
                      <img
                        loading="lazy"
                        className="video-post--video-link--contain--video-image blog-image-web"
                        src={item.blogImageWeb}
                        alt={item.blogTitle}
                      />
                      <img
                        loading="lazy"
                        className="video-post--video-link--contain--video-image blog-image-mobile"
                        src={item.blogImageMobile}
                        alt={item.blogTitle}
                      />
                      <div className="video-post--video-link--contain--video-image-gradient"></div>
                      <div className="video-post--video-link--contain--watch-video">
                        <div className="video-post--video-link--contain--watch-video--playCircle">
                          <PlayCircleFill />
                        </div>
                      </div>
                      <div className="video-post--video-link--contain--watch-video-text">
                        Watch Video
                      </div>
                      <div
                        style={
                          item.videoImageHasText
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        className="video-post--video-link--contain--text-container"
                      >
                        {item.blogTitle}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={"/inspirations/" + item.blogLink}
                    className="blog-post--blog-link"
                  >
                    <div className="blog-post--blog-link--contain">
                      <img
                        loading="lazy"
                        className="blog-post--blog-link--contain--blog-image blog-image-web"
                        src={item.blogImageWeb}
                        alt={item.blogTitle}
                      />
                      <img
                        loading="lazy"
                        className="blog-post--blog-link--contain--blog-image blog-image-mobile"
                        src={item.blogImageMobile}
                        alt={item.blogTitle}
                      />
                    </div>
                    <div className="blog-post--blog-link--text-container">
                      <p className="blog-post--blog-link--text-container--blog-title">
                        {item.blogTitle}
                      </p>
                      <p className="blog-post--blog-link--text-container--blog-author">
                        {item.blogPostAuthor}
                      </p>
                    </div>
                  </Link>
                )}
              </div>
            ))}
          </Row>
        </section>
      </main>
    </Layout>
  );
};

export default Inspirations;
